.overflow-x-hidden {
	overflow-x: hidden;
}

.overflow-c-x-auto {
	overflow-x: auto;
}

.overflow-c-y-scroll {
	overflow-y: scroll;
}

.overflow-y-auto {
	overflow-y: auto;
}

.overflow-y-visible {
	overflow-y: visible;
}