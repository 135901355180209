//.dropup {
//	.dropdown-toggle::after {
//		transform: none;
//	}
//}

.dropdown, .dropup, .dropright, .dropleft {
	.dropdown-toggle {
		position: relative;
		padding-right: 2rem;
		
		&::after {
			content: "\e9ac" !important;
			position: absolute;
			font-size: 1.1rem;
			//font-weight: 600;
			//color: vars.$gray-100;
			left: auto;
			top: 50%;
			transform: translateY(-50%);
			margin-right: .5rem;
			transition: transform 100ms ease-in-out;
		}
	}
	
	&.show {
		.dropdown-toggle::after {
			transform: translateY(-50%) rotate(180deg);
		}
	}
}

.dropdown-menu {
	z-index: 15;
	box-shadow: vars.$box-shadow-sm;
}

.dropdown-close{
  &:hover{
    color: vars.$red !important;
  }
}
