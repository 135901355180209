.gap-0-5r {
	gap: .5rem;
}

.gap-1r {
	gap: 1rem;
}

.gap-1-5r {
	gap: 1.5rem;
}

.gap-2r {
	gap: 2rem;
}

.gap-2-5r {
	gap: 2.5rem;
}

.gap-3r {
	gap: 3rem;
}

.gap-4r {
	gap: 4rem;
}

.gap-5r {
	gap: 5rem;
}

.gap-6r {
	gap: 6rem;
}

.gap-7r{
  gap: 7rem;
}