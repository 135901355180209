/* card without shadow*/
.no-card-shadow {
  .card {
    box-shadow: none;
  }
}

/*    card css    */
/*---------------*/
.card {
  margin-bottom: $spacer * 2.2;
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
  transition: all .3s ease-in-out, background 0s, color 0s, border-color 0s;

  .table-responsive {
    .table-bordered {
      /* bootstrap table bordered css override inside card */
      border: 1px solid $card-border-color;
    }
  }

  // class for text-overflow:ellipsis for specific content only
  .text-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // card shadow remove when card inside the card
  .card {
    box-shadow: none !important;
  }

  // card-subtitle
  .card-subtitle {
    color: $gray-200;
    font-weight: 400;
  }

  // card-title
  .card-title {
    font-weight: normal;
    letter-spacing: 0.05rem;
    font-size: 1.2rem;
    margin-bottom: 1.2rem;
    text-transform: capitalize;
  }

  // card-border
  .card-bordered {
    border: $card-border-width solid $card-border-color;
  }

  // card-imgage
  .card-img {
    border-radius: $card-border-radius;
  }

  // fullscreen card
  &.card-fullscreen {
    display: block;
    z-index: 9999;
    position: fixed;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: auto;
  }

  // card image overlay
  .card-img-overlay {
    border-radius: $card-border-radius;
    text-overflow: ellipsis;

    &.bg-overlay {
      background: rgba($black, 0.50);
    }

    //text color
    p,
    .card-text,
    .card-title {
      color: $white;
    }
  }

  // card header styles
  .card-header {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    // card title
    .card-title {
      margin-bottom: 0;
    }
    & + .card-content>.card-body:first-of-type,
    & + .card-body {
      padding-top: 0px;
    }

  }

  // card action css
  .heading-elements,
  .heading-elements-toggle {
    position: absolute;
    right: $card-spacer-x;
    line-height: 0;
    cursor: pointer;

    &.heading-top-elements {
      .page-item {
        display: inline-block
      }
    }

    li:not(:first-child) a {
      margin-left: 8px;
    }

    a {
      &.btn {
        padding-top: 6px;
        padding-bottom: 6px;
      }

      &[data-action="collapse"] {
        i {
          transition: all .25s ease-out;
          display: inline-block;
        }

        &.rotate {
          i {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }

  .card-footer {
    align-items: center;
    font-size: 1.2rem;
    font-weight: normal;
    color: $headings-color;
    padding: 1rem 1.9rem;
    font-family: $font-family-monospace;
  }
}

/* Card Column specific */
/*---------------------*/
.card-columns {
  .card {
    margin-bottom: $card-spacer-y * 1.4;
  }
}

// Blank page card shadow
.blank-page {
  .card.bg-authentication {
    box-shadow: -8px 20px 25px 0 rgba(25, 42, 70, 0.30);
  }
}

.card-group {
  .card {
    /* left side shadow change for card-group card only */
    box-shadow: 5px 12px 18px 0 rgba(25, 42, 70, 0.13);
  }
}

/* card-action page media query */
@include media-breakpoint-down(sm) {
  .heading-elements-toggle {
    z-index: 2;
  }

  .heading-elements {
    text-align: center;

    .list-inline {
      display: none;
    }

    &.visible {
      top: 22px !important;
      display: block;
      margin-top: 0;
      height: auto;
      left: 0px;
      padding: 10px;
      z-index: 1;
      position: absolute;
      width: 100%;

      .list-inline {
        background-color: $white;
        display: block;
        position: absolute;
        top: 15px;
        right: 20px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .heading-elements-toggle {
    display: none;
  }
}

/* IE Specific media query */
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {

  .card-body,
  .card-content {
    min-height: 1px;
  }
}
