@use "../variables/variables" as vars;

.img-c-placeh {
	position: relative;
	display: inline-block;
	vertical-align: bottom;
	
	&::before {
		position: absolute;
		//content: attr(data-placeholder);
		content: '';
		top: 50%;
		left: 50%;
		//z-index: 1;
		text-align: center;
		color: vars.$gray-100;
		font-size: .75em;
		transform: translate(-50%, -50%);
		animation: fade-in 150ms ease-in-out;
	}
}

@keyframes fade-in {
	from {
		opacity: 0
	}
	75% {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}