.modal-c-body {
	display: block;
	position: absolute;
	background: vars.$white;
	right: 0;
	top: 120%;
	max-width: 800px;
	min-width: 400px;
	border-radius: 4px;
	padding: 1rem;
	cursor: initial;
	user-select: text;
	min-height: 300px;
	max-height: 600px;
	overflow-y: auto;
	border: 1px solid #E1E1E1;
}

.modal-c-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(vars.$gray-200, .2);
	z-index: 1040;
	transition: vars.$transition-c;
	backdrop-filter: blur(5px);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-c-bg {
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, .25);
	z-index: 2 !important;
	//backdrop-filter: blur(2px);
}