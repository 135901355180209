.form-control {
	&:disabled {
		border: 1px solid rgba(vars.$gray-100, .33);
	}
	
	//&:focus {
	//box-shadow: vars.$box-shadow-sm;
	//}
	
	&-c-multiline {
		min-height: 2.5rem;
		height: auto;
	}
}

label {
	font-weight: 400;
}