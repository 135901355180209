.c-disabled {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.1 !important;
    border-radius: 0.5rem;
    background-color: black !important;
    z-index: 1;
    width: 100% !important;
  }
}