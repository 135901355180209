@use "../variables/variables" as vars;

.c-tooltip {
	position: absolute;
	top: 0;
	left: 0;
	width: max-content;
	border-radius: .5rem;
	max-width: 50vw;
	max-height: 50vh;
	list-style: none;
	padding: 0.25rem 1rem;
	background-color: vars.$white;
	color: vars.$gray-800;
	border: 1px solid vars.$dropdown-border-color;
	box-shadow: vars.$box-shadow-sm;
	overflow: auto;
	z-index: 10;
	
	.tooltip-item {
		padding: .5rem 0;
	}
	
	.tooltip-item + .tooltip-item {
		border-top: 1px solid vars.$border-color;
	}
	
	&-arrow {
		position: absolute;
		background: vars.$white;
		border-right: 1px solid vars.$dropdown-border-color;
		border-bottom: 1px solid vars.$dropdown-border-color;
		width: .75rem;
		height: .75rem;
		transform: rotate(45deg);
		z-index: 11;
	}
}

@media (min-width: 1800px) {
  .c-tooltip {
    max-width: 25vw;
  }
}