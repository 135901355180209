.pagination {
	.page-item {
		
		&.previous,
		&.next,
		&.first,
		&.last {
			.page-link {
				color: vars.$primary;
				padding: 0.5rem 1rem;
			}
			
			&.disabled {
				.page-link {
					@extend .disabled-c-btn;
				}
			}
		}
		
		.page-link {
			border: 1px solid vars.$pagination-border-color;
			border-right: 0;
			border-left: 0;
			color: vars.$gray-500;
			background-color: transparent;
			font-weight: 400;
			padding: 0.5rem .75rem;
			border-radius: .5rem !important;
			
			//&:hover {
			//	//border-radius: vars.$border-radius;
			//	//background-color: rgba(vars.$primary, 0.2);
			//}
		}
		
		//&.active {
		//	z-index: 0;
		//}
	}
	
	&.pagination-borderless {
		.page-item {
			.page-link {
				border: 1px solid transparent !important;
				border-radius: .5rem;
			}
			
			&.previous,
			&.next {
				.page-link {
					//border: 1px solid vars.$pagination-border-color !important;
					background-color: vars.$white;
					
					//&:hover {
					//	background-color: rgba(vars.$primary, 0.2);
					//}
				}
				
				//&.disabled {
				//	.page-link {
				//		background-color: vars.$input-disabled-bg;
				//	}
				//}
			}
		}
	}
}
