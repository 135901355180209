@use '../styles/custom-styles-frest/variables/variables' as vars;
.snow {
	//display: block;
	position: fixed;
	//background: vars.$bg-c-main-gradient;
	//z-index: -1;
	left: 0;
	top: 0;
	min-height: 100vh;
	min-width: 100%;
	//opacity: .5;
	//right: 0;
	//bottom: 0;
}
