.badge {
	user-select: none;
	z-index: 10;
	
	&.badge-circle-sm {
		//font-size: .75rem;
		width: 1rem;
		height: 1rem;
	}
	
	&.badge-up {
		top: 0;
		right: -0.1rem;
	}
}

.badge-c {
	padding: .25rem .75rem;
	border-radius: 100px;
}

.badge-custom-pill {
  padding: 0.4rem 1.2rem;
  display: inline-block;
  border-radius: 2rem;
}

.badge-light-success {
  color: vars.$green;
  background-color: vars.$toastify-color-light-success;
}

.badge-light-warning {
  color: vars.$yellow;
  background-color: vars.$toastify-color-light-yellow;
}

.badge-muted {
  color: vars.$gray-200;
  background-color: rgba(163, 175, 189, 0.2)
}