@use "../variables/variables" as vars;

.btn-c-audio {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	padding: 0;
	border: none;
	background-color: vars.$primary;
	border-radius: 50%;
	height: 1.8rem;
	width: 1.8rem;
	@extend %minimalTouchZone;
}

.btn-c-close {
	display: inline-block;
	width: 2.4em;
	height: 2.4em;
	position: relative;
	border-radius: 50%;
	background: none;
	border: none;
	color: transparent;
	transition: vars.$transition-c;
	
	&.white {
		&::before,
		&::after {
			background-color: vars.$white;
		}
	}
	
	&::before,
	&::after {
		transition: vars.$transition-c;
		content: "";
		@extend .abs-c-center;
		width: 1.33em;
		height: 2px;
		border-radius: 10px;
		background: vars.$body-color;
	}
	
	&::before {
		transform: translate(-50%, -50%) rotate(45deg);
	}
	
	&::after {
		transform: translate(-50%, -50%) rotate(-45deg);
	}
	
	&:hover {
		background: #FF5B5C;
		
		&::before,
		&::after {
			background: white;
		}
		
		&:active {
			background-color: vars.$red;
			border-color: vars.$red;
		}
	}
}

.btn-c-del {
	background: rgba(206, 206, 206, 0.3);
	color: vars.$gray-100;
	border: 1px solid rgba(vars.$border-color, .75);
	padding: 8px;
	transition: vars.$transition-c;
	
	&:hover {
		color: vars.$white;
		background-color: rgba(vars.$red, .8);
		border-color: rgba(vars.$red, .8);
	}
	
	&:active {
		background-color: vars.$red;
		border-color: vars.$red;
	}
}

.btn {
	transition: vars.$transition-c;
	
	&.btn-c-white {
		color: vars.$body-color !important;
		border: 1px transparent solid;
		background: vars.$white;
		
		&:hover {
			color: vars.$white !important;
		}
	}
}

.btn-c-min-w {
	min-width: 140px;
}

.btn:disabled, .disabled-c-btn {
	color: #919EAF !important;
	background-color: rgba(206, 206, 206, .29) !important;
	mix-blend-mode: normal;
	outline: 1px solid rgba(206, 206, 206, 0.5) !important;
	//border: 1px solid rgba(206, 206, 206, 0.5) !important;
}

.btn-outline-primary:hover.glow, .btn-outline-primary.hover.glow {
	box-shadow: 0 4px 12px 0 rgba(90, 141, 238, 0.6) !important;
}

.btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active {
	background-color: #437dec !important;
	color: #fff !important;
}

.btn-outline-primary.glow {
	box-shadow: 0 2px 4px 0 rgba(90, 141, 238, 0.5) !important;
}

.btn-outline-primary-white {
	color: vars.$body-color;
	background-color: #FFFFFF;
}

.btn-outline-primary-white:hover {
	color: vars.$primary;
}