.c-loading {
	position: relative;
	
	&::before, &::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		visibility: hidden;
		transition: opacity 100ms ease-in-out, width 100ms ease-in-out, visibility 0s 250ms ease-in-out;
	}
	
	&::before {
		border-radius: inherit;
		width: 0;
		z-index: 10;
		background-color: rgba(255, 255, 255, .6);
	}
	
	&::after {
		width: 2rem;
		height: 2rem;
		z-index: 11;
		margin: auto;
		border-radius: 50%;
		border: .25rem solid transparent;
		border-right-color: vars.$primary;
		border-left-color: vars.$primary;
		animation: rotate 2s ease-out 0s infinite;
	}
	
	&.small::after {
		width: 1rem;
		height: 1rem;
		border-width: .15rem;
	}
	
	&.large::after {
		width: 3rem;
		height: 3rem;
	}
	
	&.show {
		&::before, &::after {
			transition: visibility 0s 0s;
			visibility: visible;
			opacity: 1;
		}
		
		&::before {
			width: 100%;
		}
	}
}

@keyframes rotate {
	to {
		transform: rotate(3turn)
	}
}
