@use "../variables/variables" as vars;

.c-input::placeholder {
	text-transform: uppercase;
	font-size: vars.$font-size-sm;
}

.input-c-muted-picker {
	&::-webkit-calendar-picker-indicator {
		opacity: .2;
	}
}


.visually-hidden {
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
	border: 0 !important;
	height: 1px !important;
	overflow: hidden;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
}

.c-input-file {
	&::file-selector-button {
		@extend .visually-hidden;
	}
}

input[type="search"]::-webkit-search-cancel-button {
  &:hover{
    cursor: pointer
  }
}