.svg-curr-color path {
	stroke: currentColor;
	fill-opacity: 1;
}

.svg-curr-color-fill path {
	fill: currentColor;
	fill-opacity: 1;
}

.svg-service-size {
	width: 1.66rem;
	height: 1.66rem;
}

.svg-muted-color path{
  stroke: vars.$gray-100;
  fill-opacity: 1;
}

.svg-danger-color path{
  stroke: vars.$red;
  fill-opacity: 1;
}

.svg-primary-color path {
  stroke: vars.$primary;
  fill-opacity: 1;
}