@use 'src/styles/custom-styles-frest/variables/variables.scss' as vars;


.flatpickr-calendar {
	box-shadow: vars.$box-shadow !important;
	
	&::before, &::after {
		border-bottom-color: vars.$blue !important;
	}
	
	.flatpickr-months {
		height: auto;
		display: flex;
		align-items: center;
		border-bottom: 1px solid rgba(vars.$gray-100, .33);
		//border-top: 2px solid vars.$blue;
		//background: vars.$gray-100-2;
		
		.flatpickr-prev-month, .flatpickr-next-month {
			height: auto;
			position: static;
			font-weight: lighter;
			font-size: 1.5rem;
			padding: .5rem 1rem;
			color: vars.$blue;
			//float: right;
		}
		
		.flatpickr-prev-month {
			order: 2;
		}
		
		.flatpickr-next-month {
			order: 3;
		}
		
		//.arrowUp {
		//	//border: none !important;
		//
		//	&:after {
		//		//font-family: "boxicons", serif;
		//		//font-weight: bold;
		//		//content: "\e9b9";
		//		//border: none !important;
		//
		//		//content: "\e9ac";
		//	}
		//}
		
		.flatpickr-month {
			//padding: 1rem;
			text-align: left;
			height: auto;
			color: vars.$blue;
			
			//flex: 0 0 auto;
			//overflow: visible;
			order: 1;
			
			.flatpickr-current-month {
				position: static;
				padding: .5rem .25rem;
				width: unset;
				height: auto;
				//font-size: 1.33rem;
				//font-weight: 700;
				
				.cur-month {
					
					&:hover {
						background: none;
					}
					
					font-weight: 600;
					font-size: 1rem;
				}
			}
		}
	}
	
	.flatpickr-weekday {
		color: vars.$gray-100;
	}
	
	.prevMonthDay, .nextMonthDay {
		color: vars.$gray-100 !important;
		font-weight: lighter;
	}
	
	.flatpickr-day {
		border-radius: vars.$border-radius-lg;
		
		&:hover {
			background: vars.$gray-100;
			color: vars.$white;
		}
		
		&.selected {
			border-radius: vars.$border-radius-lg;
			background: vars.$blue;
			color: white !important;
		}
		
		&.startRange {
			color: vars.$white !important;
			border-top-left-radius: vars.$border-radius-lg !important;
			border-bottom-left-radius: vars.$border-radius-lg !important;
		}
		
		&.endRange {
			color: vars.$white !important;
			border-top-right-radius: vars.$border-radius-lg !important;
			border-bottom-right-radius: vars.$border-radius-lg !important;
		}
		
		&.today {
			color: vars.$blue;
			font-weight: bold;
			border-color: transparent;
		}
		
		&.disabled {
			color: vars.$gray-100;
			background: none;
		}
	}
	
	.flatpickr-disabled {
		display: initial !important;
		color: rgba(vars.$gray-100, .25) !important;
		cursor: default !important;
	}
}

.flatpickr-c-close {
	opacity: .1;
	transition: opacity 100ms, color 100ms;
	font-size: 1.5em;
	
	&:hover {
		opacity: 1;
		color: vars.$red;
	}
}
