.list-group {
	.list-group-item {
		transition: vars.$transition-c;
		//color: vars.$body-color;
		//border-color: mix(vars.$white, vars.$border-color, 75%);
	}
}

.list-gap-025r {
	li + li {
		margin-top: .25rem;
	}
}

.list-gap-05r {
	li + li {
		margin-top: .5rem;
	}
}

.list-gap-075r {
	li + li {
		margin-top: .75rem;
	}
}

.list-gap-1r {
	li + li {
		margin-top: 1rem;
	}
}

.list-gap-1-5r {
	li + li {
		margin-top: 1.5rem;
	}
}

.list-c-msg {
	background-color: vars.$gray-c-50;
	list-style: none;
	
	.img-c-msg {
		position: absolute;
	}
	
	.item-c-right {
		text-align: right;
		position: relative;
		
		.msg-c-text {
			text-align: left;
			background-color: vars.$blue;
			//@extend .glow-c-primary;
			color: vars.$white;
		}
		
		.img-c-msg {
			right: 0;
		}
	}
	
	.item-c-left {
		position: relative;
		
		.img-c-msg {
			left: 0;
		}
		
		.msg-c-text {
			background-color: vars.$white;
		}
	}
	
	.item-c-left + .item-c-right,
	.item-c-right + .item-c-left {
		margin-top: 2rem;
	}
	
	.item-c-left + .item-c-left,
	.item-c-right + .item-c-right {
		margin-top: 1rem;
		
		.img-c-msg {
			display: none;
		}
	}
}