@use "../variables/variables" as vars;

.custom-switch {
	.custom-control-label::before {
		background: vars.$gray-100;
	}
	
	.custom-control-label::after {
		height: 100%;
		width: 20px;
		left: 1px;
		top: 0;
		background-clip: padding-box;
		border: 2px solid transparent;
	}
	
	.custom-control-input:checked ~ .custom-control-label::after {
		transform: translateX(calc(100% + 4px));
		left: initial;
	}
	
	.custom-control-input:disabled ~ .custom-control-label::before {
		background: rgba(vars.$gray-100, .33);
	}
	
	@extend .c-hover-opacity;
}