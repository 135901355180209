@use "../variables/variables" as vars;

.audio {
	&-c-time {
		position: absolute;
		right: 0;
		bottom: -.5em;
		font-size: vars.$font-size-sm;
    &-lower {
      bottom: -1.5em;
    }
	}
	
	&-c-range {
		position: absolute;
		-webkit-appearance: none;
		background: transparent;
		width: 100%;
		height: 1em;
		top: 0;
		cursor: pointer;
	}
	
	&-c-range::-webkit-slider-runnable-track {
		visibility: hidden;
	}
	
	&-c-range::-webkit-slider-thumb {
		visibility: visible;
		-webkit-appearance: none;
		border: 2px solid vars.$primary;
		height: 12px;
		width: 12px;
		border-radius: 50%;
		background: vars.$white;
		@extend .hover-c-scale-lg;
	}
}