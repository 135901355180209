/*    tooltip css  */
/*----------------*/
.tooltip{
  .tooltip-inner{
    // tooltip inner box-shadow
    box-shadow: 0px 0px 10px 0px rgba(58,70,93,0.25);
  }
  // Tooltip-light scss
  &.tooltip-light {
    .tooltip-inner{
      background-color: $white;
      color: $body-color;
      border: 1px solid $border-color;
    }
    &[x-placement^="top"]{
      .arrow {
        &:before {
          /* tooltip arrow border Color */
          border-top-color: $white;
          top: -1px;

        }
      }
    }
    &[x-placement^="right"]{
      .arrow {
        &:before {
          /*rtl:begin:ignore*/
          border-right-color: $white;
          right: -1px;
          /*rtl:end:ignore*/
        }
      }
    }
    &[x-placement^="bottom"]{
      .arrow {
        &:before {
          border-bottom-color: $white;
          bottom: -1px;
        }
      }
    }
    &[x-placement^="left"]{
      .arrow {
        &:before {
          /*rtl:begin:ignore*/
          border-left-color: $white;
          left: -1px;
          /*rtl:end:ignore*/
        }
      }
    }
  }
  &.tooltip-horizontal-bookmark{
    /*  Tooltip Horizontal Layout - Bookmark Icons */
    z-index: 1000 !important;
    top: -7px;
  }
}


.bs-tooltip-right {
  padding: 0 $tooltip-arrow-height;

  .arrow {
    /*rtl:begin:ignore*/
    left: 0;
    right: auto;
    /*rtl:end:ignore*/
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      /*rtl:begin:ignore*/
      right: 0;
      border-width: ($tooltip-arrow-width / 2) $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
      border-right-color: $tooltip-arrow-color;
      /*rtl:end:ignore*/
    }
  }
}

.bs-tooltip-left {
  padding: 0 $tooltip-arrow-height;

  .arrow {
    /*rtl:begin:ignore*/
    right: 0;
    /*rtl:end:ignore*/
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      /*rtl:begin:ignore*/
      left: 0;
      border-width: ($tooltip-arrow-width / 2) 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
      border-left-color: $tooltip-arrow-color;
      /*rtl:end:ignore*/
    }
  }
}

