.rounded-md {
	border-radius: 6px;
}

.rounded-c-top-md {
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
}

.rounded-c-left-top-md {
	border-top-left-radius: 6px;
}

.rounded-c-right-top-md {
	border-top-right-radius: 6px;
}

.rounded-c-inherit {
	border-radius: inherit;
}

.disable-rounded-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.disable-rounded-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}