.transition-slide-down {
  &-enter {
    opacity: 0;
    transform: translateY(-100px);
    transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
  }

  &-enter-active {
    opacity: 1;
    transform: translateY(0);
  }

  &-exit {
    opacity: 1;
    transform: translateY(-100px);
    transition: opacity 200ms, transform 200ms;
  }

  &-exit-active {
    opacity: 0;
  }
}

.transition-fade {
  opacity: 0;

  &-enter {
    opacity: 0;
    transition: opacity 200ms;
  }

  &-enter-active {
    opacity: 1;
  }

  &-exit {
    opacity: 1;
    transition: opacity 200ms;
  }

  &-exit-active {
    opacity: 0;
  }
}