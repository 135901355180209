.border-c-transparent {
	border-color: transparent;
}

.border-bottom-transparent {
	border-bottom-color: transparent !important;
}

.border-top-extra-light {
  border-top : 1px solid #E3E7EB;
}

.border-bottom-extra-light {
  border-bottom : 1px solid #E3E7EB;
}

.border-left-extra-light {
  border-right : 1px solid #E3E7EB;
}

.border-right-extra-light {
  border-left : 1px solid #E3E7EB;
}

//.border-c-gray-05 {
//	border: solid rgba(199, 207, 214, .5);
//}
