//.abs-c {
//	left: 0;
//}

.abs-c-center {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.abs-c-left-center {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
}

.abs-c-right-center {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}