// =========================================================================================
//     File Name: navigations.scss
//     Description: Common mixin for menus, contain dark and light version scss.
//     ----------------------------------------------------------------------------------------
//     Item Name: Frest HTML Admin Template
//     Version: 1.0
//     Author: PIXINVENT
//     Author URL: http://www.themeforest.net/user/pixinvent
// ==========================================================================================

// Import main menu mixin
@import "../mixins/main-menu-mixin";

/* Main Menu Base */
/* -------------- */

.main-menu {
  z-index: 1031;
  position: absolute;
  display: table-cell;
  height: 100%;
  overflow: hidden;

  // Perfect Scrollbar
  .ps__thumb-y {
    background-color: #d1d7de;
  }

  &.menu-light {
    @include menu-theme(
      $body-color,
      $body-bg,
      $border-color,
      $menu-light-sub-bg,
      $menu-light-active-bg
    );
  }

  &.menu-dark {
    @include menu-theme(
      $menu-dark-color,
      $menu-dark-bg-color,
      $theme-dark-border-color,
      $menu-dark-bg-color,
      $menu-dark-active-bg
    );
  }

  &.menu-fixed {
    position: fixed;
    top: 0;
  }
  &.menu-static {
    height: auto;
    top: 0;
    padding-bottom: calc(100% - 40rem);
    .main-menu-content {
      height: unset !important;
    }
  }

  .shadow-bottom {
    /* Menu Scroll Shadow */
    display: none;
    position: absolute;
    z-index: 2;
    height: 60px;
    width: 100%;
    pointer-events: none;
    margin-top: -1.3rem;
    filter: blur(5px);
    background: linear-gradient(
      #f2f4f4 41%,
      hsla(0, 0%, 100%, 0.11) 95%,
      hsla(0, 0%, 100%, 0)
    );
  }
  .navbar-header {
    height: 100%;
    width: $menu-expanded-width;
    height: $navbar-height - 0.4;
    position: relative;
    padding: 0.35rem 1.45rem 0.3rem 1.3rem;
    transition: 300ms ease all, background 0s;
    cursor: pointer;
    z-index: 3;
    .navbar-brand {
      margin-top: 0.75rem;
      display: flex;
      align-items: center;
      .brand-logo {
        height: 27px;
        width: 35px;
        float: left;
        margin-top: 0.2rem;
        margin-left: 3px;
        .logo {
          height: 26px;
          display: flex;
          position: relative;
          left: 6px;
        }
      }

      .brand-text {
        color: $primary;
        padding-left: 0.7rem;
        font-weight: 500;
        letter-spacing: 0.04rem;
        font-size: 1.5rem;
        float: left;
        animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1
          fadein;
      }
    }
    .modern-nav-toggle {
      animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1
        fadein;
      margin: 0.75rem 0 0;
    }
  }

  .main-menu-content {
    height: calc(100% - 6rem) !important;
    position: relative;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    &.navigation-main {
      overflow-x: hidden;
    }
  }
}

/* Main Navigation */
.navigation {
  font-family: $font-family-monospace;
  overflow-y: hidden;
  padding-bottom: 20px;

  .navigation-header {
    padding: 12px 22px;
    font-size: 0.8rem;
    text-transform: uppercase;
  }

  li {
    position: relative;
    white-space: nowrap;

    a {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &.disabled {
      a {
        cursor: not-allowed;
      }
    }
  }
}

.dropdown-notification {
  padding: 13px 0;
  .nav-link-label {
    position: relative;
  }
  .media-heading {
    margin-bottom: 0.2rem;
    font-size: 0.8rem;
  }
  .notification-text {
    margin-bottom: 0.5rem;
    font-size: smaller;
    color: $gray-200;
  }

  .notification-tag {
    position: relative;
    top: -4px;
  }
  .dropdown-menu.dropdown-menu-right {
    right: -2px;
    padding: 0;
    &::before {
      background: $primary;
      border-color: $primary;
    }
    .scrollable-container {
      .read-notification {
        background-color: #f2f4f4;
      }
    }
  }
  .dropdown-menu-header {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    background: $primary;
    .dropdown-header {
      color: $white;
    }
  }
}

// Collapsed Static Layout
.menu-collapsed {
  .menu-static {
    padding-bottom: calc(100% - 14rem);
  }
}

// Media Queries For Small Screen Devices
@include media-breakpoint-down(sm) {
  // Menu Hide/Open Animation
  .menu-hide .main-menu,
  .menu-open .main-menu {
    transition: transform 0.25s, top 0.35s, height 0.35s;
  }

  .main-menu {
    transform: translate3d(-240px, 0, 0);
    backface-visibility: hidden;
    perspective: 1000;
  }

  .menu-open .main-menu {
    transform: translate3d(0, 0, 0);
  }
}

// Media Queries For Medium Screen Devices
@include media-breakpoint-up(md) {
  .drag-target {
    z-index: 0;
  }
}

// Fixed Footer Layout Set Menu-Fixed Height

.fixed-footer {
  .main-menu {
    &.menu-fixed {
      height: 100%;
    }
  }
}

// Media Queries Till Medium Screen Devices
@media (max-width: 992px) {
  body {
    .main-menu {
      &.menu-static {
        padding-bottom: 100%;
      }
    }
  }
}
