.checkbox {
	width: 20px;
	height: 20px;
	
	&:hover label:before {
		box-shadow: vars.$box-shadow;
	}
	
	label {
		&:before {
			background: vars.$white;
		}
		
		&:before, &:after {
			border-color: vars.$blue-c-light;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	
	input:checked ~ label:after {
		top: 50% !important;
		transform: translateY(-75%) rotate(-45deg) !important;
	}
}
