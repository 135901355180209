.width-85-per {
	width: 85%;
}

.width-18-per {
	width: 18%;
}

.width-48-per {
	width: 48%;
}

.width-175 {
	width: 175px;
}

.c-min-w-100p {
	min-width: 100%;
}

.w-c-content {
	width: max-content;
}

.w-c-auto {
	width: auto;
}

.scrollbar-width {
  &::-webkit-scrollbar {
    width: 5px !important;
  }
}