.swiper {
	&-button-next,
	&-button-prev {
		top: calc(40% - 25vh) !important;
		height: 75% !important;
		width: 5rem !important;
		color: white !important;
		
		&:after {
			font-size: 2rem !important;
		}
		
		&.swiper-button-disabled {
			pointer-events: visible !important;
		}
		
		&:hover {
			background-color: rgba(85, 85, 85, 0.2);
		}
	}
	
	&-pagination {
		bottom: 1rem !important;
		
		&-bullet {
			width: .75rem !important;
			height: .75rem !important;
		}
	}
}