/*   Toasts css   */
/*---------------*/

/* default toast css */
.toast {
  &.show {
    z-index: 1030;
  }
  .close:focus {
    outline: none;
  }

  // default toast header
  .toast-header {

    // icon on header
    i {
      font-size: 1.4rem;
      margin-right: .6rem;
    }

    // toast-title for toast heading
    .toast-title {
      font-size: 1.2rem;
      font-weight: normal;
    }

    // close btn
    .close {
      padding: 0.1rem .25rem;
      border-radius: 1.5rem;
      margin-left: 1rem;
      opacity: 1;
      color: $gray-600;
      background-color: $hover-color;

      &:focus,
      &:active {
        outline: none;
      }

      // close icon
      i {
        font-size: 1.2rem;
        margin: 0;
      }
    }
  }

  .toast-body {
    padding: $toast-padding-y $toast-padding-x; // apply to both vertical and horizontal
  }

  // if toast-light class with toaster
  &.toast-light {
    .toast-header {
      background-color: transparent;
      border-bottom: 1px solid $toast-header-border-color;

      span {
        color: $gray-600;
      }

      i {
        color: $gray-600;
      }

      small {
        color: $gray-200;
      }
    }
  }

  &.toast-translucent {
    /* translucent toast opacity and display */
    opacity: .85;
    display: block;
  }
}
@include media-breakpoint-down(xs) {
  .toast {
    left: 1rem;
  }
}
