.accordion-wrapper {
	//background: white;
	//box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	padding-top: .5rem;
	border: 2px solid transparent;
	transition: border 350ms ease;
}

.accordion-wrapper__header {
	display: flex;
	flex: 1;
	cursor: pointer;
	padding: 20px 20px 0;
	align-items: center;
	justify-content: space-between;
}

.accordion-wrapper__header-toggle-icon {
	background: none;
	border: none;
	display: flex;
	transform: rotate(0);
}

.accordion-wrapper__header-toggle-icon svg {
	fill: black;
	transition: all 0.4s linear;
}

.accordion-wrapper__header-toggle-icon--isOpen svg {
	transform: rotate(180deg);
}

.accordion-wrapper__content-wrapper {
	padding: 0 20px 10px;
	margin: 0;
}

.accordion-wrapper__content-body {
	padding-top: 10px;
	cursor: auto;
}

/* CSSTransition specific classes starts here */
.accordion-wrapper__content-enter {
	overflow: hidden;
	max-height: 0;
}

.accordion-wrapper__content-enter-active {
	max-height: 1000px;
	transition: max-height 0.8s ease-in-out;
}

.accordion-wrapper__content-exit {
	max-height: 1000px;
}

.accordion-wrapper__content-exit-active {
	overflow: hidden;
	max-height: 0;
	transition: max-height 0.6s cubic-bezier(0, 1, 0, 1);
}